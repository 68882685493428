import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import InvitationsService from '../../../service/invitations/invitations-service';
import SimpleSnackbar, {SnackbarState} from '../../../components/snackbars/SimpleSnackbar';
import SignUp from './SignUp';
import {useHistory, useLocation} from 'react-router';
import BitLoading from '../../../components/loading/BitLoading';
import AuthService from '../../../service/auth/auth-service';
import SignIn from './SignIn';
import AlreadyInvited from './AlreadyInvited';
import {AddUserResponse} from '../../../bkp/bitkey-platform-api';
import {PersonType} from '../Completed';
import {useMediaQuery} from '@material-ui/core';
import PasswordReset from './PasswordReset';
import {Locale} from '../../../dictionary/dictionary-common';
import {useCommonDictionary, useDictionary} from '../../../dictionary/Dictionary';
import LanguageButton from '../../../components/buttons/LanguageButton';
import {FirestoreOrganizationBusinessType} from '@bitkey-service/workhub-types/lib/firestore/organizations/firestoreTypesOrganizations';

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Box = styled.div`
  position: absolute;
  width: 360px;
  height: 540px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-shadow: ${({theme}) => (!theme.isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : undefined)};
  border-radius: 12px;
`;

export interface ProcessState {
  processing: boolean;
  message: string;
}

export enum AuthenticationType {
  Email = 'Email',
}

const dictDef = {
  something: {
    default: {
      default: '',
      [Locale.en_US]: '',
    },
  },
  loadingInvitationInformation: {
    default: {
      default: '招待情報を読み込んでいます...',
      [Locale.en_US]: 'Loading Invitation Information...',
    },
  },
  passwordMissing: {
    default: {
      default: 'パスワードが未入力です',
      [Locale.en_US]: 'Password is not filled',
    },
  },
  noInvitationInformation: {
    default: {
      default: '招待情報がありません',
      [Locale.en_US]: 'Invitation Information not found',
    },
  },
  checkEmail: {
    default: {
      default: 'メールをご確認ください',
      [Locale.en_US]: 'Please check the email that have been sent',
    },
  },
  failed: {
    default: {
      default: '失敗しました',
      [Locale.en_US]: 'Failed',
    },
  },
  loggingInBitlock: {
    default: {
      default: 'ログイン中です...',
      [Locale.en_US]: 'Logging in bitlock service...',
    },
  },
  contactBitlock: {
    default: {
      default: '招待情報の読み込みに失敗しました。Bitkey社にお問い合わせください。',
      [Locale.en_US]: 'Failed to load invitation information. Please contact bitkey.inc',
    },
  },
};

const InvitationsForGuest: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [businessType, setBusinessType] = useState<FirestoreOrganizationBusinessType>();
  const [userId, setUserId] = useState<string>();
  const [createOrganization, setCreateOrganization] = useState<boolean>();
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [email, setEmail] = useState('');

  const [personaId, setPersonaId] = useState<string>();
  const [orgName, setOrgName] = useState('Bitkey社');

  const [hasBitlockAccount, setHasBitlockAccount] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false);
  const [showAlreadyRegistered, setShowAlreadyRegistered] = useState<boolean>(false);
  const [tenantName, setTenantName] = useState('');
  const [passwordResetMode, setPasswordResetMode] = useState<boolean>(false);
  const params = useMemo(
    () =>
      location.search
        .replace('?', '')
        .split('&')
        .reduce((prev, cur) => {
          const {0: key, 1: value} = cur.split('=');
          prev[key] = value;
          return prev;
        }, {} as {[key: string]: string}),
    [location.search]
  );
  const invitationId = useMemo(() => params['i'], [params]);
  const organizationId = useMemo(() => params['o'], [params]);
  const [processing, setProcessing] = useState<ProcessState>({
    processing: true,
    message: dict.loadingInvitationInformation,
  });
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    success: false,
  });
  // 基本ここでやるのは、アカウントを作ること。
  const onClickCreateAccount = useCallback(
    async (password: string) => {
      setProcessing({
        processing: true,
        message: commonDict.integratingService,
      });
      if (!password) {
        setSnackbarState({
          open: true,
          message: dict.passwordMissing,
          success: false,
        });
        setProcessing({
          processing: false,
          message: '',
        });
        return;
      }
      if (!password.match(/^.{8,56}$/i)) {
        setSnackbarState({
          open: true,
          message: commonDict.passwordLengthError,
          success: false,
        });
        setProcessing({
          processing: false,
          message: '',
        });
        return;
      }

      if (!password.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_{|}~]+$/i)) {
        setSnackbarState({
          open: true,
          message: commonDict.enterHalfWidthLetters,
          success: false,
        });
        setProcessing({
          processing: false,
          message: '',
        });
        return;
      }

      if (!invitationId) {
        setSnackbarState({
          open: true,
          message: dict.noInvitationInformation,
          success: false,
        });
        setProcessing({
          processing: false,
          message: '',
        });
        return;
      }
      try {
        const addUserRes: AddUserResponse = await AuthService.addUserToBkp({
          email: email,
          authType: AuthenticationType.Email,
        });

        setSnackbarState({
          message: dict.checkEmail,
          success: true,
          open: true,
        });

        history.push('/invitations/activate', {
          email: email,
          authType: AuthenticationType.Email,
          password: password,
          signupSessionId: addUserRes.signupSessionId,
          organizationId: organizationId,
          invitationId: invitationId,
          businessType: businessType,
          createOrganization: createOrganization,
          userId: userId,
          tenantName: tenantName,
        });
      } catch (e) {
        setSnackbarState({
          message: dict.failed,
          success: false,
          open: true,
        });
        setProcessing({
          processing: false,
          message: '',
        });
      }
    },
    [
      commonDict.integratingService,
      commonDict.passwordLengthError,
      commonDict.enterHalfWidthLetters,
      invitationId,
      dict.passwordMissing,
      dict.noInvitationInformation,
      dict.checkEmail,
      dict.failed,
      email,
      history,
      organizationId,
      businessType,
      createOrganization,
      userId,
      tenantName,
    ]
  );

  const onLogin = useCallback(
    async (password: string) => {
      try {
        setProcessing({
          processing: true,
          message: dict.loggingInBitlock,
        });
        // ログイン処理
        // TODO bkp側のバグで、一度組織からアカウントを削除するとメンバーのemailが消え去る不具合があるため
        // personaIdがある場合はpersonaIdを利用してauthを通す。
        personaId
          ? await AuthService.signInWithPersonaId({
              personaId,
              password,
            })
          : await AuthService.signIn({
              email,
              password,
            });

        if (createOrganization) {
          history.push('/invitations/addOrganization', {
            organizationId: organizationId,
            email: email,
            password: password,
            tenantName: tenantName,
          });
          return;
        }

        if (alreadyRegistered) {
          // 登録済み画面へ遷移させる
          setShowAlreadyRegistered(true);
          setProcessing({
            processing: false,
            message: '',
          });
          return;
        }
        // 招待の承認処理
        setProcessing({
          processing: true,
          message: commonDict.integratingService,
        });
        const result = await InvitationsService.accept(organizationId, invitationId);

        history.push('/completed', {
          personType: PersonType.guest_temporary,
          sentKeys: result.sentKeys,
        });
      } catch (e) {
        setSnackbarState({
          message: e.message,
          success: false,
          open: true,
        });
      } finally {
        setProcessing({
          processing: false,
          message: '',
        });
      }
    },
    [
      dict.loggingInBitlock,
      personaId,
      email,
      createOrganization,
      alreadyRegistered,
      commonDict.integratingService,
      organizationId,
      invitationId,
      history,
      tenantName,
    ]
  );

  const gotoPasswordReset = useCallback(() => {
    setPasswordResetMode(true);
  }, []);
  const onCancelPasswordReset = useCallback(() => {
    setPasswordResetMode(false);
  }, []);

  const getServiceInfo = useCallback(async () => {
    InvitationsService.v2FindInvitation({
      invitationId,
    })
      .then(res => {
        setEmail(res.email);
        if (res.tenantName) {
          setTenantName(res.tenantName);
        }
        setCreateOrganization(res.createOrganization);
        setHasBitlockAccount(res.hasBitlockAccount);
        setAlreadyRegistered(res.alreadyRegistered);
        if (res.organizationName) setOrgName(res.organizationName);
        setProcessing({
          processing: false,
          message: '',
        });
        setUserId(res.userId);
        setPersonaId(res.personaId);
        setBusinessType(res.businessType);
      })
      .catch(() => {
        setProcessing({
          processing: true,
          message: 'ユーザー情報を読み込んでいます',
        });
      })
      .finally(() => {
        setProcessing({
          processing: false,
          message: '',
        });
      });
  }, [invitationId]);

  // 初回アクセス時にinvitationIdから取得
  useEffect(() => {
    getServiceInfo().catch(console.log);
  }, [invitationId, organizationId, params, dict, getServiceInfo]);

  return (
    <Frame>
      <LanguageButton />
      {processing.processing ? (
        <BitLoading isMobile={isMobile} message={processing.message} />
      ) : (
        <Box theme={{isMobile}}>
          {showAlreadyRegistered ? (
            <AlreadyInvited authType={AuthenticationType.Email} identifyValue={email} />
          ) : (
            <>
              {hasBitlockAccount ? (
                <>
                  {passwordResetMode ? (
                    <PasswordReset identifyValue={email} onCancel={onCancelPasswordReset} />
                  ) : (
                    <SignIn
                      email={email}
                      authenticateType={AuthenticationType.Email}
                      orgName={orgName}
                      onLogin={onLogin}
                      onClickPasswordReset={gotoPasswordReset}
                    />
                  )}
                </>
              ) : (
                <SignUp
                  email={email}
                  authenticateType={AuthenticationType.Email}
                  orgName={orgName}
                  onClickCreateAccount={onClickCreateAccount}
                  shouldDisplayTerm={true}
                />
              )}
            </>
          )}
        </Box>
      )}
      <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
    </Frame>
  );
};

export default InvitationsForGuest;
